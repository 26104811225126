<template>
  <div id="addEdit">
     <el-page-header @back="goBack" :title="title"></el-page-header>
      <el-form :model="formData">
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="对象名称" style="width: 100%">
                    <el-input v-model="formData.name"  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="对象编码" style="width: 100%">
                     <!-- <el-input v-model="formData.code"  autocomplete="off"></el-input> -->
                     <el-select
                        size="small"
                        style="width:100%"
                        v-model="formData.code"
                        placeholder="请选择"
                        ><el-option
                          v-for="item in codeList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.code"
                        ></el-option
                    ></el-select>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="应用编码" style="width: 100%">
                    <el-input v-model="formData.appCode"  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="数据库表名" style="width: 100%">
                    <el-input v-model="formData.tableName"  autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="状态" style="width: 100%">
                     <el-select
                                size="small"
                                style="width:100%"
                                v-model="formData.status"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                  </el-form-item>
                   <el-form-item label="父级对象" style="width: 100%">
                    <!-- <el-input v-model="formData.name"  autocomplete="off"></el-input> -->
                    <SelectTree  :props="props"
                    :options="list"
                    :value="valueId"
                    :clearable="isClearable"
                    :accordion="isAccordion"
                    @getValue="getValue($event)"
                    ></SelectTree>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="微服务标识" style="width: 100%">
                    <el-input v-model="formData.microServiceId"  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="对象目录" style="width: 100%">
                    <el-select
                        size="small"
                        style="width:100%"
                        v-model="formData.objCatalog"
                        placeholder="请选择"
                        ><el-option
                          v-for="item in catoryList"
                          :key="item.val"
                          :label="item.mean"
                          :value="item.val"
                        ></el-option
                    ></el-select>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item class="last" label="备注" style="width: 100%">
                    <el-input type="textarea" v-model="formData.note" maxlength="255"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <div style="padding-left:30px">
        <el-button type="primary" size="mini" plain @click="camcel">取消</el-button>
        <el-button type="primary" size="mini" @click="submit" >保存</el-button>
      </div>
  </div>
</template>

<script>
import { reqAdd, reqEdit, reqDetail, reqObjectTree } from '@/api/metadataManagement/metadataManagement.js'
import { reqStytemList } from '@/api/question/safty.js'
import SelectTree from '../treeSelect.vue'
export default {
    name: 'ivyobjectAE',
    components: {
        SelectTree
    },
    data () {
        return {
            formData: {
                name: '',
                // code: '',
                appCode: '',
                microServiceId: '',
                // parentCode: '',
                tableName: '',
                note: '',
                objCatalog: '',
            },
            title: '',
            statusList: [
                { value: 1, label: '无效' },
                { value: 0, label: '有效' }
            ],
            type: '',
            catoryList: [],
            isClearable: true, // 可清空（可选）
            isAccordion: true, // 可收起（可选）
            valueId: '', // 初始ID（可选）
            props: {
                // 配置项（必选）
                value: 'code',
                label: 'name',
                children: 'children'
                // disabled:true
            },
            // 选项列表（必选）
            list: [
            ],
            codeList: []
        }
    },
    created () {
        this.type = this.$route.query.type
        if (this.$route.query.type === 'add') {
            this.title = '新增对象'
        } else {
            this.title = '编辑对象'
            reqDetail(this.$route.query.id).then((res) => {
                console.log(res)
                this.formData = res.data
                this.valueId = res.data.parentCode
            })
        }
        reqStytemList().then((res) => {
            this.codeList = res.data.records
        })
        reqObjectTree({ lazy: false }).then((res) => {
            this.list = res.data
        })
        this.$utils.getDicData('OBJECT_OBJ_CATALOGS').then((res) => {
            this.catoryList = res
        })
    },
    methods: {
        getValue (value) {
            this.valueId = value
            // console.log(this.valueId)
            this.formData.parentCode = value
        },
        goBack () {
            this.$router.go(-1)
        },
        submit () {
            if (this.type === 'add') {
                // 新增
                reqAdd(this.formData).then((res) => {
                    console.log(res)
                    if (res.code === 0) {
                        this.$message.success('新增成功')
                        this.goBack()
                    }
                })
            } else {
                reqEdit(this.formData).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('编辑成功')
                        this.goBack()
                    }
                })
            }
        },
        camcel () {
            this.goBack()
        }
    }
}
</script>

<style lang="scss">
#addEdit{
  .el-col-12{
    display: flex;
  }
  .el-form-item{
    flex: 1;
    display: flex;
  }
  .el-form-item .el-form-item__label{
    width: 102px !important;
  }
  .last .el-form-item__label{
    width: 92px !important;
  }
  .el-form-item__content{
    width: 90%;
  }
  .el-textarea__inner{
    height: 160px !important;
  }
}
</style>
